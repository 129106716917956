<template>
    <div>
        <b-button-toolbar class="mb-3">
            <b-button-group class="ml-auto">
                <b-button to="list">
                    <b-icon icon="list" />
                    Список
                </b-button>
            </b-button-group>
        </b-button-toolbar>
        <b-overlay :show="!stages || request">
            <b-form
                autocomplete="off"
                @submit="submit"
            >
                <b-form-group
                    label="Email"
                    label-for="email"
                >
                    <b-form-input
                        id="email"
                        v-model="form.email"
                        type="text"
                        placeholder="Введите email"
                        required
                        :state="errors.email ? false : null"
                        autocomplete="off"
                    />
                    <b-form-invalid-feedback>
                        <ul>
                            <li
                                v-for="error in errors.email"
                                :key="error"
                            >
                                {{ error }}
                            </li>
                        </ul>
                    </b-form-invalid-feedback>
                </b-form-group>
                <b-form-group
                    label="Пароль"
                    label-for="password"
                >
                    <b-form-input
                        id="password"
                        v-model="form.password"
                        type="password"
                        placeholder="Введите пароль"
                        required
                        :state="errors.password ? false : null"
                        autocomplete="new-password"
                    />
                    <b-form-invalid-feedback>
                        <ul>
                            <li
                                v-for="error in errors.password"
                                :key="error"
                            >
                                {{ error }}
                            </li>
                        </ul>
                    </b-form-invalid-feedback>
                </b-form-group>
                <b-form-group
                    label="ФИО"
                    label-for="name"
                >
                    <b-form-input
                        id="name"
                        v-model="form.name"
                        type="text"
                        placeholder="Введите ФИО"
                        required
                        :state="errors.name ? false : null"
                        autocomplete="off"
                    />
                    <b-form-invalid-feedback>
                        <ul>
                            <li
                                v-for="error in errors.name"
                                :key="error"
                            >
                                {{ error }}
                            </li>
                        </ul>
                    </b-form-invalid-feedback>
                </b-form-group>
                <b-form-group
                    label="Аккаунт коллектора"
                    label-for="telegram_username"
                >
                    <b-form-input
                        id="telegram_username"
                        v-model="form.telegram_username"
                        type="text"
                        placeholder="Введите telegram"
                        :state="errors.telegram_username ? false : null"
                        autocomplete="off"
                    />
                    <b-form-invalid-feedback>
                        <ul>
                            <li
                                v-for="error in errors.telegram_username"
                                :key="error"
                            >
                                {{ error }}
                            </li>
                        </ul>
                    </b-form-invalid-feedback>
                </b-form-group>
                <b-form-group
                    label="Тип"
                    label-for="type"
                >
                    <b-form-select
                        id="type"
                        v-model="form.type"
                        :options="types"
                        placeholder="Укажите тип"
                        required
                        :state="errors.type ? false : null"
                        autocomplete="off"
                    />
                    <b-form-invalid-feedback>
                        <ul>
                            <li
                                v-for="error in errors.type"
                                :key="error"
                            >
                                {{ error }}
                            </li>
                        </ul>
                    </b-form-invalid-feedback>
                </b-form-group>
                <b-form-group
                    label="Стадия"
                    label-for="stage_id"
                >
                    <b-form-select
                        id="stage"
                        v-model="form.stage_id"
                        :options="stages"
                        placeholder="Укажите стадию"
                        required
                        :state="errors.stage_id ? false : null"
                        autocomplete="off"
                    />
                    <b-form-invalid-feedback>
                        <ul>
                            <li
                                v-for="error in errors.stage_id"
                                :key="error"
                            >
                                {{ error }}
                            </li>
                        </ul>
                    </b-form-invalid-feedback>
                </b-form-group>
                <b-form-group
                    label="Вес"
                    label-for="weight"
                >
                    <b-form-input
                        id="weight"
                        v-model="form.weight"
                        type="text"
                        placeholder="Введите вес"
                        required
                        :state="errors.weight ? false : null"
                        autocomplete="off"
                        :number="true"
                    />
                    <b-form-invalid-feedback>
                        <ul>
                            <li
                                v-for="error in errors.weight"
                                :key="error"
                            >
                                {{ error }}
                            </li>
                        </ul>
                    </b-form-invalid-feedback>
                </b-form-group>

                <b-button
                    type="submit"
                    variant="primary"
                >
                    Сохранить
                </b-button>
            </b-form>
        </b-overlay>
    </div>
</template>

<script>
import UserTypeEnum from "../../../enums/UserTypeEnum";

export default {
    data() {
        return {
            form: {
                email: null,
                password: null,
                name: null,
                telegram_username: null,
                type: null,
                stage_id: 0,
                weight: 100,
            },
            errors: {},
            request: false,
            stages: null,
        }
    },
    computed: {
        types() {
            return Object.keys(UserTypeEnum).map((key) => {
                return {value: key, text: UserTypeEnum[key]}
            });
        },
    },
    mounted() {
        this.jsonRpc("admin.stages.fullList").then(async (response) => {
            this.stages = response.map((stage) => {
                return {value: stage.id, text: stage.name};
            });
            this.stages.unshift({value: 0, text: "Не выбрана"});
        });
    },
    methods: {
        submit(event) {
            event.preventDefault();

            if (this.request) {
                return;
            }

            this.request = true;

            this
                .jsonRpc("admin.users.add", {...this.form})
                .then(async (result) => {
                    this.$alertify.success("Запись добавлена");
                    this.$router.push({path: `${result.id}`});
                })
                .catch(async ({error}) => {
                    if (this.$formValidation.isValidationRpcResponse(error)) {
                        this.errors = this.$formValidation.parse(error.data);
                    }
                })
                .finally(async () => {
                    this.request = false;
                });

        },
    }
}
</script>
